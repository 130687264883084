import React, { useState } from 'react';

import { TabsList , List, Cell, Badge} from '@telegram-apps/telegram-ui'
import { MyInvite } from './MyInvite';
import { useUser } from '../../UserContext'; // Adjust path if necessary
import '../../App.css'

function Tabs() {
    const [selectedTab, setSelectedTab] = useState('tab1');
    const { userRefData } = useUser(); // Access userData from context
    const listOfFrens = userRefData?.result;
  return (
    <div>
              {/* Add margin directly to the TabsList */}
      <TabsList> 
        <TabsList.Item 
          selected={selectedTab === 'tab1'}
          onClick={() => setSelectedTab('tab1')}
        >
          My Invite Link
        </TabsList.Item>
        <TabsList.Item
          selected={selectedTab === 'tab2'}
          onClick={() => setSelectedTab('tab2')}
        >
          My Frens
        </TabsList.Item>
      </TabsList>


      {selectedTab === 'tab2' && (
          <div style={{ padding: '0 20px' }}>
            <List 
        style={{
          background: 'var(--tgui--primary_bg_color)',
          width: '100%',
        }}>
            
     {listOfFrens.map((item, index) => (
    <div className="Lists">

            <Cell after={<Badge type="number">+{item.reward}</Badge>}
             key={index}>
            {`${item.Friend.firstName} ${item.Friend.lastName || ""}`}
            </Cell>
    </div>
          ))}


            {/* {[...Array(3)].map((_, index) => (

            <Cell after={<Badge type="number">+1000</Badge>}
             key={index}>
              Friend Name {index}
            </Cell>
            
          ))} */}
            </List>
                
          </div>
        )}
                {selectedTab === 'tab1' && (  <MyInvite/> )}


    </div>
  )
}

export default Tabs