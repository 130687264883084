import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { AppRoot, List, Chip, Avatar, Image, Input, Divider } from '@telegram-apps/telegram-ui';
import { MyTabbar } from './components/MyTabbar';
import NewtonLogo from '../src/images/apologonobg.png';
import LoadingPage from './components/LoadingPage';
import { retrieveLaunchParams } from '@telegram-apps/sdk';
import { useUser } from './UserContext';
import { postEvent } from '@telegram-apps/sdk';

export let authtoken = null;

export const App = () => {
    const [isInitializing, setIsInitializing] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const { initData, initDataRaw, startParam } = retrieveLaunchParams();
    const [result, setResult] = useState('');
    const { userData, setUserData } = useUser();
    const [user, setUser] = useState(null);
    const [isMinLoadingComplete, setIsMinLoadingComplete] = useState(false);


    // Initialize Telegram WebApp immediately
    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.ready();
        const telegramUser = tg.initDataUnsafe?.user || null;
        setUser(telegramUser);

        const { secondary_bg_color } = tg.themeParams || {};
        if (secondary_bg_color) {
            document.body.style.backgroundColor = secondary_bg_color;
        }
        
        setIsInitializing(false);
        postEvent('web_app_set_header_color', { color_key: 'secondary_bg_color' });
    }, []);
 
    // Add this effect for minimum loading time
        useEffect(() => {
            const timer = setTimeout(() => {
                setIsMinLoadingComplete(true);
            }, 2000);

            return () => clearTimeout(timer);
        }, []);

    // Handle authentication
    useEffect(() => {
        if (isInitializing) return;

        const authenticate = async () => {
            try {
                const authResponse = await axios.post('https://lets-connect.org/api/auth/login', {
                    "initData": initDataRaw,
                    "referralToken": startParam,
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        "accept": "application/json"
                    }
                });
                setResult(authResponse.data);
                authtoken = authResponse.data?.access_token;
            } catch (error) {
                console.error('Authentication error:', error);
            }
        };

        authenticate();
    }, [initDataRaw, isInitializing]);

    // Fetch user data
    useEffect(() => {
        if (!authtoken) return;

        const fetchUserData = async () => {
            try {
                const response = await fetch('https://lets-connect.org/api/user/me', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': '*/*',
                        'Cache-Control': 'no-cache',
                        'Connection': 'keep-alive',
                        'Authorization': `Bearer ${authtoken}`
                    },
                });
                const data = await response.json();
                setUserData(data);
                setIsDataLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setIsDataLoading(false);
            }
        };

        fetchUserData();
    }, [authtoken, setUserData]);

    // Show loading page while initializing or loading data
    if (isInitializing || isDataLoading || !isMinLoadingComplete) {
        return <AppRoot><LoadingPage /></AppRoot>;
    }

    return (
        <AppRoot>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                overflow: 'hidden',
            }}>
                <List style={{
                    background: 'var(--tgui--secondary_bg_color)',
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexShrink: 0,
                }}>
                    <div style={{
                        display: 'flex',
                        gap: 25,
                        alignItems: 'center',
                        backgroundColor: 'var(--tgui--primary_bg_color)'
                    }}>
                        <Chip mode="elevated" before={<Avatar size={20} />}>
                            {user?.first_name || 'Guest'}
                        </Chip>
                        <Image
                            size={64}
                            src={NewtonLogo}
                            alt="App logo"
                        />
                        <Chip mode="elevated" before={<Avatar size={20} />}>
                            {userData?.tokens}
                        </Chip>
                    </div>
                </List>
                <Divider/>
                <MyTabbar />
            </div>
        </AppRoot>
    );
};

export default App;