// import React from 'react';
// import { SegmentedControl,Cell,Spoiler } from '@telegram-apps/telegram-ui';
// function LearnSection() {
//   return (
//     <div >
//         <SegmentedControl>
//   <SegmentedControl.Item
//     disabled
//   >
//     Courses
//   </SegmentedControl.Item>
//   <SegmentedControl.Item
//     disabled
//   >
//     Events
//   </SegmentedControl.Item>
 
// </SegmentedControl>
// <Spoiler>
// <Cell >
//       Courses and events are on the way! <br/> For paid ones, you can pay with $Eureka token.
//     </Cell>

//   </Spoiler>
//     </div>
//   )
// }

// export default LearnSection
import React, { useState } from 'react';
import { Input, Modal, Placeholder, List, Cell, Section, Badge, Avatar , Tappable} from '@telegram-apps/telegram-ui';
import { ArrowRight, Clock, Pin } from 'lucide-react';
import '../App.css';

export default function LearnSection() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [value, setValue] = useState('');

  const events = [
    {
      id: 1,
      title: "ورکشاپ مقدماتی پایتون",
      description: "A virtual workshop for learning basic Python.",
      location: "Virtual",
      time: "12 hrs",
      cost: "Free",
    },
    // {
    //   id: 2,
    //   title: "Machine Learning Bootcamp",
    //   description: "An in-depth course on Machine Learning.",
    //   location: "On-site",
    //   time: "24 hrs",
    //   cost: "$50",
    // },
  ];

  const handleCellClick = (event) => {
    // Close the modal first to reset its state, then open it with new data
    setIsModalOpen(false);
    setTimeout(() => {
      setSelectedEvent(event);
      setIsModalOpen(true);
    }, 0);
  };

  return (
    <div className="Lists">
      <List
        style={{
          background: 'var(--tgui--secondary_bg_color)',
          width: '100%',
        }}
      >
        <Section
          header="Active Events"
          footer="Newton is Beta Version! Soon more events will appear here."
        >
          {events.map((event) => (
            <Cell
              key={event.id}
              onClick={() => handleCellClick(event)}
              after={<ArrowRight size={36} />}
              before={<Avatar size={48} />}
              description={
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Pin size={16} />
                  <span>{event.location}</span>
                </div>
              }
              subhead={event.cost}
              subtitle={
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Clock size={16} />
                  <span>{event.time}</span>
                </div>
              }
              titleBadge={<Badge type="dot" />}
            >
              {event.title}
            </Cell>
          ))}
        </Section>
      </List>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false) } style={{ zIndex: 9999,  }}>
        {selectedEvent ? (
        <List style={{
          width: 400,
          maxWidth: '100%',
          margin: 'auto',
         // background: 'var(--tgui--secondary_bg_color)'
        }}>
            <Input header="Input" placeholder="I am usual input, just leave me alone" />
            <Input status="error" header="Input" placeholder="I am error input, don't make my mistakes..." />
            <Input status="focused" header="Input" placeholder="I am focused input, are u focused on me?" />
            <Input disabled header="Input" placeholder={selectedEvent.title} />
           <Input status="focused" header="Input" placeholder="Write and clean me" value={selectedEvent.title} onChange={e => setValue(e.target.value)} after={<Tappable Component="div" style={{
            display: 'flex'
          }} onClick={() => setValue('')}>
                  <Clock />
                </Tappable>} />
          </List>
        ) : null}
      </Modal>
    </div>
  );
}
