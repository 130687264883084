import React, { useState, useEffect } from 'react';
import {List, Cell, TabsList, Image, Button, Placeholder,Badge } from '@telegram-apps/telegram-ui';
import { MyInvite } from './MyInvite';
import frensImage from './Frens.png';
import Tabs from './Tabs';
import { authtoken } from '../../App.js';
import { useUser } from '../../UserContext'; // Adjust path if necessary
import { init, openTelegramLink } from '@telegram-apps/sdk-react';
// export let totalFrens = null;
// export let listOfFrens = null;
init()
export const FrensSection = () => {



    // const [userRefData, setUserRefData] = useState(null); // obsolete 

    const { userData, setUserRefData, userRefData } = useUser(); // Access userData from context
    const userRefToken = userData.referralToken;


  

    console.log(authtoken);
    useEffect(() => {
      fetch('https://lets-connect.org/api/user/referral?limit=10&offset=0', {
          method: 'GET',
          headers: {
              
              'Content-Type': 'application/json', // 
              'accept': '*/*', // 
              'Cache-Control': 'no-cache',
              'Connection': 'keep-alive',
              'Authorization': `Bearer ${authtoken}`  
          },
      })
          .then(response => {
              // if (!response.ok) {
              //     throw new Error(`HTTP error! Status: ${response.status}`);
              // }
              // console.log('refdata',response.json())
              return response.json();
          }) 
          .then(data => {
             console.log('refdata then',data)

              setUserRefData(data);

              // console.log('refdat',userRefData);
              // totalFrens = userRefData?.total;
              // listOfFrens = userRefData?.result;
              // console.log('list',listOfFrens);
              // console.log('num',totalFrens);


          })
        // .catch(error => console.error('Error:', error));
  }, [authtoken]);


  console.log(userRefData);

  const [selectedTab, setSelectedTab] = useState('tab1');
  const shareUrl = `https://t.me/share/url?url=https://t.me/newton_desci_bot/app?startapp=${userRefToken}&text=Join%20NEWTON%20now%20and%20get%201,000%20Eureka%20tokens!`;
  const yourFrensSentence = `You have ${userRefData?.total} Frens`
  return (
      <div 
        style={{
          height: 'calc(100vh - 180px)', 
          overflowY: 'auto', 
          overflowX: 'hidden', 
        }}
      >
       
          <div 
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <Placeholder
                action={    <>     <Button   Component="a"
                  href={shareUrl}   target="_blank"
                  size="l" stretched   onClick={(e) => {
                    e.preventDefault(); // Prevent the default behavior
                    openTelegramLink(shareUrl, '_blank'); // Open in a new tab
                  }}>Invite Now!</Button>
                <Tabs/></>

                }
                description="Invite a fren and you both get a reward of 1000 Eureka!"
                header={yourFrensSentence}
                className="w-full h-auto"
              >
                <img
                  alt="Telegram sticker"
                  src={frensImage}
                  style={{
                    maxWidth: "150%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </Placeholder>
            </div>
          </div>
     

      </div>
  );
};

export default FrensSection;